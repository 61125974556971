/* Modal */
.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Ensure modal is above all content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content1 {
  background-color: white;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  max-width: 80%; /* Limit maximum width of modal content */
  width: 500px; /* Adjust width as needed */
}

.close1 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #555;
}

/* Box */
.box1 {
  border: 3px solid #ccc;
  padding: 20px;
  margin: 20px auto; /* Center the box horizontally */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  max-width: 60%;
  padding: 3%;
  margin-left: 4%;
  position: relative;
  display: flex;
  flex-direction: column; /* Arrange content in a column */
}

/* Project, Leader, Team Members */
.project1,
.leader1,
.team-members1 {
  margin-bottom: 20px;
}

/* Labels and Values */
.label1 {
  font-weight: bold;
}

.value1 {
  font-weight: bold;
}

/* Delete Button */
.button1 {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: auto; 
}

button1:hover {
  background-color: #e04109;
}

/* Status */
.status1 {
  background-color: #a89c9c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(230, 13, 13, 0.1); /* Add shadow for depth */
}

/* Broker Details */
.broker-details1 {
  margin-left: 20px;
}

.button-291 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-291:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-291:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-291:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

.delete11 {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
}

.edit1 {
  position: absolute;
  top: 5px; /* Adjust as needed */
  left: 5px; /* Adjust as needed */
}
