/* Profile.css */
.profile-container {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width:115px;
    height:60px
 
  }

  .notify1{
 position: absolute;
 
  }

