.popup{
    position: fixed;
    top: 0;
    left: 0;
    width:180vh;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    margin-left: 16.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-inner{
    position: relative;
    padding: 20px;
    width: 100%;
    height: 10rem;
    max-width: 660px; 
    background-color: rgba(134, 134, 139, 0.5);
    border-radius: 10px;
}

.popup-inner .close-btn{
    position: absolute;
    top:6px;
    right: 15px;
    color: whitesmoke;
    font-size: large;
    
}
.notify{
    position: fixed;
    top: 0;
    left: 0;
    width:180vh;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    margin-left: 16.2rem;
    padding-left: 29rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 35rem;
    z-index: 10;
}


.notify-inner h4{
    width: 100%;
    border: 1px solid black;
}
.notify-inner .close-btn{
    position: absolute;
    top:6px;
    right: 15px;
    color: whitesmoke;
    font-size: large;
    
}