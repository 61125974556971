@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply tw-bg-theme-black tw-text-white tw-overflow-x-hidden;
    word-break: break-all;
    font-family: "Inter", sans-serif;
}

:root {
    --color-light-bg: #ffffff;
    --color-dark-bg: #1b1b1f;
    --color-accent: #FC585B;
    --color-accent2:rgb(227, 84, 86)B;
    --color-accent-lighter: #b03e41;
    --color-accent-lighter2: #ffd6d7;
    --color-accent-lighter3: #ffacae;
    --color-accent-darker2: #ffb5b6;
    --color-dark-border: #424956;
    --fontFamily: "Inter", "sans-serif";
    --color-input-dark: #161618;
    --color-input-light: #f6f6f7;
    --color-accent-rgba: rgba(252, 88, 91, 1);
    --color-accent-rgba2: rgba(252, 88, 91, 0.7);
    --color-accent-lighter3-rgba: rgba(255, 172, 174, 1);
    --color-accent-lighter3-rgba2: rgba(255, 172, 174, 0.7);

  }
  
  /* Light Theme */
  body.light-mode {
    background-color: var(--color-light-bg);
    font-family: "Inter", sans-serif;
  }
  
  /* Dark Theme */
  body.dark-mode {
    background-color: var(--color-dark-bg);
    font-family: "Inter", sans-serif;
  }
  
  /* Scrollbar */
  body .MuiDrawer-paper::-webkit-scrollbar {
    width: 8px;
  }
  
  body .MuiDrawer-paper::-webkit-scrollbar-thumb {
    background-color: var(--color-accent);
    border-radius: 4px;
  }
  
  body .MuiDrawer-paper::-webkit-scrollbar-track {
    background-color: var(--color-light-bg);
  }
  
  body.dark-mode .MuiDrawer-paper::-webkit-scrollbar-track {
    background-color: var(--color-dark-border);
  }
  
  